import React from 'react';
import { Grid } from '@mui/material';
import styles from './founding-membership.module.scss';
import { BetaTesterImg } from '../../../../assets/images';

const FoundingMembership: React.FC = () => {
  return (
    <section className={styles.container} id="founding-membership">
      <Grid container spacing={4} alignItems="center">
        <Grid item xs={12} md={7}>
          <h2>Founding Membership for Beta Testers!</h2>
          <div className={styles.benefitsList}>
            <ul>
              <li>Be an Early Adopter and gain first access to QuickCam</li>
              <li>Ground-Floor Opportunity</li>
              <li>Earn Game Coins that convert to Community Meme Coins</li>
              <li>Earn Badges that convert into NFTs</li>
            </ul>
          </div>
        </Grid>
        <Grid item xs={12} md={5}>
          <div className={styles.badgeContainer}>
            <img
              src={BetaTesterImg}
              alt="Founding Member Beta Tester Badge"
              className={styles.badgeImage}
            />
          </div>
        </Grid>
      </Grid>
    </section>
  );
};

export default FoundingMembership;
