import React from 'react';
import ContactDetails from '../../common/components/footer-links/contact.component';
import {
  FooterLinkContainer,
  FooterLinkTitle,
  FooterLinkContent,
  FooterLinkHeading,
  FooterLinkSubHeading,
  FooterLinkContentList,
  FooterLinkContentBulletItem,
} from '../../common/components/footer-links/footer-links.component';
import styles from '../../common/components/footer-links/footer-links.module.scss';

const InvestorContainer: React.FunctionComponent = () => {
  return (
    <div>
      <div
        style={{
          display: 'flex',
          marginBottom: '50px',
          padding: '50px',
          justifyContent: 'space-between',
        }}
      >
        <div
          style={{
            fontSize: '20px',
          }}
        >
          Quickcam - AI by Promanager LLC.
        </div>
        <div
          style={{
            background: '#007BFF',
            color: 'white',
            padding: '16px',
            borderRadius: '1px',
            cursor: 'pointer',
          }}
          onClick={() => {
            window.open('https://quickcam.dealraise.com/book', '_blank');
          }}
        >
          {/* <img
            src="/assets/icons/calendly.svg"
            alt="Schedule Meeting"
            style={{ marginRight: '8px' }} */}
          Schedule Meeting
        </div>
      </div>
      <FooterLinkContainer>
        <video width="100%" height="480" controls>
          <track
            src={`${process.env.PUBLIC_URL}/captions.vtt`}
            kind="captions"
            srcLang="en"
            label="English captions"
            default
          />
          <source
            src="/assets/video/InvestorIntroVideov7.mov"
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>
        <div style={{ height: '750px' }}>
          <iframe
            src="/assets/video/QuickCam-AIbyProManager,LLC..pdf"
            width="100%"
            height="100%"
            style={{ border: 'none' }}
            title="Quickcam"
          />
        </div>
      </FooterLinkContainer>
    </div>
  );
};

export default InvestorContainer;
