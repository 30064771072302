import React from 'react';
import styles from './free-beta.module.scss';
import freeIcon from '../../../../assets/images/free_icon.png';

const FreeBeta = () => {
  return (
    <section className={styles.container}>
      {/* Top "Free" banner */}
      <div className={styles.freeTopBanner}>
        {Array.from({ length: 10 }, (_, index) => (
          <div key={index} className={styles.freeItem}>
            <img src={freeIcon} alt="Free Icon" className={styles.freeIcon} />
          </div>
        ))}
      </div>

      {/* Main content area */}
      <div className={styles.contentArea}>
        <div className={styles.innerContainer}>
          <div className={styles.leftContent}>
            <h2 className={styles.mainHeading}>
              100% Free <br />
              in Beta
            </h2>
            <p className={styles.subHeading}>
              Subscription starts on
              <br />
              Official Launch.
            </p>
          </div>

          <div className={styles.rightContent}>
            <h3 className={styles.whyJoinHeading}>Why join now?</h3>
            <ul className={styles.benefitsList}>
              <li>
                <span className={styles.icon}>👍</span> Get early notification
                for QuickCam camera app Beta launch
              </li>
              <li>
                <span className={styles.icon}>🛡️</span> Get Beta Test Founding
                Member NFT Badge
              </li>
              <li>
                <span className={styles.icon}>🚀</span> Get early notification
                for Quickie and Slowie Community Meme Coin launches
              </li>
              <li>
                <span className={styles.icon}>🤝</span> Pre-build referral
                network and followers
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FreeBeta;
